import React from 'react';
import { Link } from 'gatsby';
import SimplePageSection from '../../../containers/SaasModern/SimplePage';

const CookiePolicy = () => {
  const content = (
    <div>
      <h1>Cookie policy</h1>
      <p>
        This cookie policy (&quot;Policy&quot;) describes what cookies are and
        how VueSoft LLC (&quot;VueSoft LLC&quot;, &quot;we&quot;, &quot;us&quot;
        or &quot;our&quot;) uses them on the PrintableCal website.
      </p>
      <p>
        You should read this Policy so you can understand what type of cookies
        we use, the information we collect using cookies and how that
        information is used. It also describes the choices available to you
        regarding accepting or declining the use of cookies. For further
        information on how we use, store and keep your personal data secure, see
        our <Link to="/About/Privacy-Policy">Privacy Policy</Link>.
      </p>
      <h2>What are cookies?</h2>
      <p>
        Cookies are small pieces of data stored in text files that are saved on
        your computer or other devices when websites are loaded in a browser.
        They are widely used to remember you and your preferences, either for a
        single visit (through a &quot;session cookie&quot;) or for multiple
        repeat visits (using a &quot;persistent cookie&quot;).
      </p>
      <p>
        Session cookies are temporary cookies that are used during the course of
        your visit to the Website, and they expire when you close the web
        browser.
      </p>
      <p>
        Persistent cookies are used to remember your preferences within our
        Website and remain on your desktop or mobile device even after you close
        your browser or restart your computer. They ensure a consistent and
        efficient experience for you while visiting our Website or using our
        Services.
      </p>
      <p>
        Cookies may be set by the Website (&quot;first-party cookies&quot;), or
        by third parties, such as those who serve content or provide advertising
        or analytics services on the website (&quot;third party cookies&quot;).
        These third parties can recognize you when you visit our website and
        also when you visit certain other websites.
      </p>
      <h2>What type of cookies do we use?</h2>
      <h3>Functionality cookies</h3>
      <p>
        Functionality cookies let us operate the Website and our Services in
        accordance with the choices you make. For example, we will recognize if
        you arrived at our website through an approved affiliate.
      </p>
      <h3>Analytical cookies</h3>
      <p>
        These cookies enable us and third-party services to collect aggregated
        data for statistical purposes on how our visitors use the Website. These
        cookies do not contain personal information such as names and email
        addresses and are used to help us improve your user experience of the
        Website.
      </p>
      <h2>What are your cookie options?</h2>
      <p>
        If you don't like the idea of cookies or certain types of cookies, you
        can change your browser's settings to delete cookies that have already
        been set and to not accept new cookies. To learn more about how to do
        this or to learn more about cookies, visit{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.internetcookies.org"
        >
          internetcookies.org
        </a>
        .
      </p>
      <h2>Changes and amendments</h2>
      <p>
        We reserve the right to modify this Policy relating to the Website or
        Services at any time, effective upon posting of an updated version of
        this Policy on the Website. When we do we will revise the updated date
        at the bottom of this page. Continued use of the Website after any such
        changes shall constitute your consent to such changes.
      </p>
      <h2>Acceptance of this policy</h2>
      <p>
        You acknowledge that you have read this Policy and agree to all its
        terms and conditions. By using the Website or its Services you agree to
        be bound by this Policy. If you do not agree to abide by the terms of
        this Policy, you are not authorized to use or access the Website and its
        Services.
      </p>
      <h2>Contacting us</h2>
      <p>
        If you would like to contact us to understand more about this Policy or
        wish to contact us concerning any matter relating to our use of cookies,
        you may do so via the{' '}
        <Link to="/#contact_us_section">contact form</Link>.
      </p>
      <p>This document was last updated on December 8, 2019.</p>
    </div>
  );

  return (
    <SimplePageSection
      title="Cookie Policy"
      description="This cookie policy describes what cookies are and how VueSoft LLC uses them on the printablecal.com website."
      keywords="cookies, privacy, GPDR compliance"
      content={content}
    />
  );
};

export default CookiePolicy;
